/* For sending contact form. Found in contact */
function send_contact_form() {
    jQuery("#email-sending").show();
    var url = "http://marksnowdon.office.lan/send";

    var name = $("#name").val();
    var email = $("#email").val();
    var message = $("#message").val();

    contact_data = {
	name: name,
	email: email,
	message: message
    };

    $.ajax({
	type: "POST",
	url: url,
	data: contact_data,
	success: function(data) {
		console.log(data);
	    // do a message sent message
	    $('#contact-block').html('<p>Thank you for getting in touch, I will get back to you shortly at: '  + contact_data['email'] + '.</p>');

		console.log("success");
	},
    error: function(XMLHttpRequest, textStatus, errorThrown) {
        alert("Status: " + textStatus); alert("Error: " + errorThrown);
    }
    });

    console.log("done");
    return false;
}



$(function() {
		$('.lazy').lazy({
				effect: "fadeIn",
				effectTime: 500,
				threshold: 0
		});

		jQuery("#contact-form" ).submit(function( event ) {
		  //alert( "Handler for .submit() called." );
		  event.preventDefault();
		  send_contact_form();
		});
});


